import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useFormsSetting() {
  // Use toast
  const toast = useToast()

  const formBuilderFieldTypes = {
    STEP: 'step',
    PLAIN_TEXT: 'plain_text',
    SHORT_TEXT: 'short_text',
    LONG_TEXT: 'long_text',
    EMAIL: 'email',
    NUMBER: 'number',
    PHONE: 'phone',
    DATE: 'date',
    DROPDOWN: 'dropdown',
    MULTI_SELECT: 'multi_select',
    CHECKBOX: 'checkbox',
    RADIO_BUTTON: 'radio_button',
    FILE_UPLOAD: 'file_upload',
  }

  const postForm = queryParams => axios
    .post('/auth/forms', queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'New form has posted',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error posting form',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const updForm = queryParams => axios
    .put(`/auth/forms/${queryParams.id}`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'From was successfully updated',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data.id
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error updating form',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const fetchForm = ID => axios
    .get(`/auth/forms/${ID}`)
    .then(response => response.data.data)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching form',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    postForm,
    updForm,
    fetchForm,
    formBuilderFieldTypes,
  }
}
