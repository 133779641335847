<template>
  <b-modal
    id="move-to-folder-modal"
    ref="move-to-folder-modal"
    centered
    title="Move to Folder"
    ok-title="Save Changes"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    @show="setModalData"
    @ok="saveChanges"
  >
    <b-overlay
      id="overlay-background"
      :show="isLoading"
      variant="white"
      opacity="1"
      rounded="sm"
    >
      <validation-observer
        ref="moveToFolderRules"
        tag="form"
      >
        <b-row>

          <b-col
            cols="12"
          >
            <b-form-group
              label="Parent Folder"
            >
              <validation-provider
                #default="{ errors }"
                name="Parent Folder"
                rules="required"
              >
                <v-select
                  v-model="selectedParentFolder"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="foldersList"
                  label="name"
                  :reduce="option => option.id"
                  placeholder="Select option"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </b-overlay>
  </b-modal>
</template>

<script>
import axios from '@axios'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

import {
  BRow,
  BCol,
  BFormGroup,
  BOverlay,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import useFoldersList from '@/views/components/folders-list/useFoldersList'

export default {
  name: 'MoveToModal',
  components: {
    ValidationProvider,
    ValidationObserver,

    BRow,
    BCol,
    BFormGroup,
    BOverlay,

    vSelect,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    folderType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      selectedParentFolder: null,
      foldersList: [],
      required,
    }
  },
  setup() {
    const {
      setFolder,
    } = useFoldersList()

    return {
      setFolder,
    }
  },
  methods: {
    setModalData() {
      this.selectedParentFolder = this.item.folder_id
      if (!this.foldersList.length) {
        this.fetchFoldersList()
      }
    },
    async fetchFoldersList() {
      try {
        this.isLoading = true
        // const response = await this.$store.dispatch('app-folders/fetchFoldersList', { type: this.folderType })
        const response = await axios
          .get('/auth/folder', {
            params: { type: this.folderType },
          })
          .then(response => response)
        this.foldersList = response.data.data
      } catch {
        this.foldersList = []
      } finally {
        this.isLoading = false
      }
    },
    saveChanges(bvModalEvt) {
      bvModalEvt.preventDefault()

      return new Promise(() => {
        this.$refs.moveToFolderRules.validate().then(success => {
          if (success) {
            const queryParams = {
              id: this.item.id,
              type: this.folderType,
              folder_id: this.selectedParentFolder,
            }

            this.setFolder(queryParams).then(() => {
              this.$emit('refetchData')
              this.$store.commit('app-folders/TOGGLE_FORCE_RELOAD')
              this.$bvModal.hide('move-to-folder-modal')
            })
          }
        })
      })
    },
  },
}
</script>
